import React from 'react';
import Carousel from './carousel';
import HomeInfo from './home-info';

const Home = () => {
    return (
        <>
            <Carousel></Carousel>
            <HomeInfo></HomeInfo>
        </>
    );
}

export default Home;
