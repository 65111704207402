import React from 'react';
import banner_1 from "../assets/banner_1.jpg";
import banner_2 from "../assets/banner_2.jpg";

const Carousel = () => {
    return (
        <div id="carouselExampleCaptions" class="carousel slide top-border" data-bs-ride="false">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
            <div class="carousel-inner d-flex align-items-center">
                <div class="carousel-item active">
                    <img src={banner_1} className="d-block w-100" alt="..." />

                </div>
                <div class="carousel-item">
                    <img src={banner_2} className="d-block w-100" alt="..." />
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    )
}
export default Carousel;
