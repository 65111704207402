import React from 'react';

const AKCStandard = () => {
    return (
        <div className="container akc-standard pb-5">
            <div className="row">
                <h1 className="text-center pb-4 pt-5">American Kennel Club <br />Official Bichon Frise Breed Standard</h1>
                <div className="col-lg-9 mx-auto p-3">
                    <h5>General Appearance</h5>
                    <p>The Bichon Frise is a small, sturdy, white powder puff of a dog whose merry temperament is evidenced by his plumed tail carried jauntily over the back and his dark-eyed inquisitive expression. This is a breed that has no gross or incapacitating exaggerations and therefore there is no inherent reason for lack of balance or unsound movement. Any deviation from the ideal described in the standard should be penalized to the extent of the deviation. Structural faults common to all breeds are as undesirable in the Bichon Frise as in any other breed, even though such faults may not be specifically mentioned in the standard.</p>
                    <h5>Size, Proportion, Substance</h5>
                    <h6>Size</h6>
                    <p>Dogs and bitches 9 1/2 to 11 1/2 inches are to be given primary preference. Only where the comparative superiority of a specimen outside this range clearly justifies it should greater latitude be taken. In no case, however, should this latitude ever extend over 12 inches or under 9 inches. The minimum limits do not apply to puppies.</p>
                    <h6>Proportion</h6>
                    <p>The body from the forward-most point of the chest to the point of rump is 1/4 longer than the height at the withers. The body from the withers to lowest point of chest represents 1/2 the distance from withers to ground.</p>
                    <h6>Substance</h6>
                    <p>Compact and of medium bone throughout; neither coarse nor fine.</p>
                    <h5>Head-Expression</h5>
                    <p>Soft, dark-eyed, inquisitive, alert.</p>
                    <h6>Eyes</h6>
                    <p>Eyes are round, black or dark brown and are set in the skull to look directly forward. An overly large or bulging eye is a fault as is an almond shaped, obliquely set eye. Halos, the black or very dark brown skin surrounding the eyes, are necessary as they accentuate the eye and enhance expression. The eye rims themselves must be black. Broken pigment, or total absence of pigment on the eye rims produce a blank and staring expression, which is a definite fault. Eyes of any color other than black or dark brown are a very serious fault and must be severely penalized.</p>
                    <h6>Ears</h6>
                    <p>Ears are drop and are covered with long flowing hair. When extended toward the nose, the leathers reach approximately halfway the length of the muzzle. They are set on slightly higher than eye level and rather forward on the skull, so that when the dog is alert they serve to frame the face.</p>
                    <h6>Skull</h6>
                    <p>The skull is slightly rounded, allowing for a round and forward looking eye. The stop is slightly accentuated.</p>
                    <h6>Muzzle</h6>
                    <p>A properly balanced head is three parts muzzle to five parts skull, measured from the nose to the stop and from the stop to the occiput. A line drawn between the outside corners of the eyes and to the nose will create a near equilateral triangle. There is a slight degree of chiseling under the eyes, but not so much as to result in a weak or snipy foreface. The lower jaw is strong.</p>
                    <h6>Nose</h6>
                    <p>The nose is prominent and always black.</p>
                    <h6>Lips</h6>
                    <p>Lips are black, fine, never drooping.</p>
                    <h6>Bite</h6>
                    <p>Bite is scissors. A bite which is undershot or overshot should be severely penalized. A crooked or out of line tooth is permissible, however, missing teeth are to be severely faulted.</p>
                    <h5>Neck, Topline and Body</h5>
                    <h6>Neck</h6>
                    <p>The arched neck is long and carried proudly behind an erect head. It blends smoothly into the shoulders. The length of neck from occiput to withers is approximately 1/3 the distance from forechest to buttocks. </p>
                    <h6>Topline</h6>
                    <p>The topline is level except for a slight, muscular arch over the loin.</p>
                    <h6>Body</h6>
                    <p>The chest is well developed and wide enough to allow free and unrestricted movement of the front legs. The lowest point of the chest extends at least to the elbow. The rib cage is moderately sprung and extends back to a short and muscular loin. The forechest is well pronounced and protrudes slightly forward of the point of shoulder. The underline has a moderate tuck-up.</p>
                    <h6>Tail</h6>
                    <p>Tail is well plumed, set on level with the topline and curved gracefully over the back so that the hair of the tail rests on the back. When the tail is extended toward the head it reaches at least halfway to the withers. A low tail set, a tail carried perpendicularly to the back, or a tail which droops behind is to be severely penalized. A corkscrew tail is a very serious fault.</p>
                    <h5>Forequarters-Shoulders</h5>
                    <p>The shoulder blade, upper arm and forearm are approximately equal in length. The shoulders are laid back to somewhat near a forty-five degree angle. The upper arm extends well back so the elbow is placed directly below the withers when viewed from the side.</p>
                    <h6>Legs</h6>
                    <p>Legs are of medium bone, straight, with no bow or curve in the forearm or wrist. The elbows are held close to the body.</p>
                    <h6>Pasterns</h6>
                    <p>The pasterns slope slightly from the vertical. The dewclaws may be removed.</p>
                    <h6>Feet</h6>
                    <p>The feet are tight and round, resembling those of a cat and point directly forward, turning neither in nor out.</p>
                    <h6>Pads</h6>
                    <p>Pads are black.</p>
                    <h6>Nails</h6>
                    <p>Nails are kept short.</p>
                    <h5>Hindquarters</h5>
                    <p>The hindquarters are of medium bone, well angulated with muscular thighs and spaced moderately wide. The upper and lower thigh are nearly equal in length meeting at a well bent stifle joint. The leg from hock joint to foot pad is perpendicular to the ground. Dewclaws may be removed. Paws are tight and round with black pads.</p>
                    <h5>Coat</h5>
                    <p>The texture of the coat is of utmost importance. The undercoat is soft and dense, the outercoat of a coarser and curlier texture. The combination of the two gives a soft but substantial feel to the touch which is similar to plush or velvet and when patted springs back. When bathed and brushed, it stands off the body, creating an overall powder puff appearance. A wiry coat is not desirable. A limp, silky coat, a coat that lies down, or a lack of undercoat are very serious faults.</p>
                    <h6>Trimming</h6>
                    <p>The coat is trimmed to reveal the natural outline of the body. It is rounded off from any direction and never cut so short as to create an overly trimmed or squared off appearance. The furnishings of the head, beard, moustache, ears and tail are left longer. The longer head hair is trimmed to create an overall rounded impression. The topline is trimmed to appear level. The coat is long enough to maintain the powder puff look which is characteristic of the breed.</p>
                    <h5>Color</h5>
                    <p>Color is white, may have shadings of buff, cream or apricot around the ears or on the body. Any color in excess of 10% of the entire coat of a mature specimen is a fault and should be penalized, but color of the accepted shadings should not be faulted in puppies.</p>
                    <h5>Gait</h5>
                    <p>Movement at a trot is free, precise and effortless. In profile the forelegs and hind legs extend equally with an easy reach and drive that maintain a steady topline. When moving, the head and neck remain somewhat erect and as speed increases there is a very slight convergence of legs toward the center line. Moving away, the hindquarters travel with moderate width between them and the foot pads can be seen. Coming and going, his movement is precise and true.</p>
                    <h5>Temperament</h5>
                    <p>Gentle mannered, sensitive, playful and affectionate. A cheerful attitude is the hallmark of the breed and one should settle for nothing less.</p>
                    <p><strong>AKC Approved October 11, 1988</strong></p>
                </div>
            </div>
        </div>
    );
}

export default AKCStandard;
