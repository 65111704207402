import React from 'react';
import george from "../assets/george.jpg";
import paloma from "../assets/paloma.jpg";
import cosmo from "../assets/cosmo.jpg";
import charlotte from "../assets/charlotte.jpg";


const DamsAndSires = () => {
    return (
        <div className="container cards">
            <div className="row">
                <h1 className="text-center pb-4 pt-5">Dam and Sires</h1>
            </div>
            <div class="row offset-sm-1 mt-3 mb-5">
                <div class="col-sm-5 mb-3 mb-sm-0">
                    <div class="card text-bg-light mb-3">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={george} class="img-fluid" alt="..."></img>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">GCHG DeMartini's HRH Prince George of Cambridge</h5>
                                    <p class="card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 mb-3 mb-sm-0">
                    <div class="card text-bg-light mb-3">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={paloma} class="img-fluid" alt="..."></img>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">CH DeMartini's A Little Piece of Picasso</h5>
                                    <p class="card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 mb-3 mb-sm-0">
                    <div class="card text-bg-light mb-3">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={charlotte} class="img-fluid" alt="..."></img>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">GCH DeMartini's HRH Princess Charlotte of Cambridge</h5>
                                    <p class="card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 mb-3 mb-sm-0">
                    <div class="card text-bg-light mb-3">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={cosmo} class="img-fluid" alt="..."></img>
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">CH DeMartini's Donatello Master of the Arts</h5>
                                    <p class="card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DamsAndSires;
