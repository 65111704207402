import React from 'react';
import { Link } from "react-router-dom";


const Breed = () => {
    return (
        <div className="top-border">
            <div className="container">
                <div className="row">
                    <h1 className="text-center pb-4 pt-5">About The Breed</h1>
                    <p className="col-lg-6 mx-auto text-center p-3">
                        Bichons are affectionate, cheerful, playful, and easy to live with.
                        They are adaptable companions who get on well with other dogs and children. They are alert and curious, and make nice little watchdogs. Their confidence and size make them ideal city dogs.
                    </p>
                </div>
                <div className="row">
                    <h4 className="text-center pb-4 pt-5">Something</h4>
                    <div className="col-lg-6 mx-auto text-center p-2">
                        <p>
                        </p>

                    </div>

                </div>
                <div className="row">
                    <h4 className="text-center pb-4 pt-5">Something</h4>
                    <div className="col-lg-6 mx-auto text-center p-2">
                        <Link to="/akc-standard" class="btn btn-outline-secondary btn-lg px-4">AKC Standard</Link>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default Breed;
