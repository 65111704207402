import React from 'react';
import ResponsiveGallery from "react-responsive-gallery";

const champions = [
    {
        src: 'https://demartinibichonfrises.com/static/media/George1.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/Geroge_best_breed1.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/tv_george.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/George_westminster_2020.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/George3.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/George5.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/George10.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/Paloma.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/George_smiles.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/Geroge_best_breed.jpg'
    },


];

const puppies = [
    {
        src: 'https://demartinibichonfrises.com/static/media/pup1.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup_huddle2.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup_charlotte.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup4.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup5.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/many_pups.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup11.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup10.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/pup_face.jpg'
    },
    {
        src: 'https://demartinibichonfrises.com/static/media/double_pup.jpg'
    },


];



const Gallery = () => {
    return (
        <div className="container col-lg-8 mb-5 gallery">
            <div className="row">
                <h1 className="text-center pb-4 pt-5">Champions</h1>
            </div>
            <div className="row mt-3">
                <ResponsiveGallery images={champions} />
            </div>
            <div className="row mt-3">
                <h1 className="text-center pb-4 pt-5">Puppies</h1>
            </div>
            <div className="row mt-3">
                <ResponsiveGallery images={puppies} />
            </div>
        </div>
    );
}

export default Gallery;
