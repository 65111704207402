import React from 'react';

const About = () => {
    return (
        <div className="top-border">
            <div className="container">
                <div className="row">
                    <h1 className="text-center pb-4 pt-5">About Us</h1>
                    <p className="col-lg-6 mx-auto text-center p-3">
                    Known for "Best In Show" Bichon Frises, Donna DeMartini has been passionate about the caring and keeping of Bichon Frises for more than 20 years.
                    After rescuing her first Bichon in 1996, Missy, Donna knew there was no other breed for her and her family.
                    In 2004, Donna adopted her first show dog, Bianca, who became succesful rather quickly!
                    Her lineage has given birth to a handful of champions that Donna has had the joy of raising, caring for, and witness succeed.
                    Donna's bichons have competed in dog shows through out the country, including Westminster.
                    Donna resides in Granite Bay, CA with her 6 Bichon Frises; Gigi, George, Paloma, Charlotte, Royce and Cosmo.
                    Donna is also an educator with 40+ years in her field.
                    Bichons are her pride and joy so she takes great care to find the perfect home for her babies.
                    </p>
                </div>
                <div className="row">
                    <h4 className="text-center pb-4 pt-5">Contact Info</h4>
                    <div className="col-lg-6 mx-auto text-center p-2">
                        <p>
                            <a href="mailto:demartinidonna991@gmail.com" target="_blank">DeMartini's Bichons</a>
                        </p>
                        <p>Granite Bay, California</p>
                        <p>(916) 847 0151</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
