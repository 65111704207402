import React from 'react';

const HomeInfo = () => {
    return (
        <div className='container'>
            <div class="col-lg-7 mx-auto pt-5 pb-5">
                <h5></h5>
                <p></p>
                <br />
                <br />
                <h5></h5>
                <p> </p>
                <br />
            </div>
        </div>
    );
}

export default HomeInfo;
