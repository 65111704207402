import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from './components/navbar';
import About from './components/about';
import DamsAndSires from './components/damsandsires';
import Breed from './components/breed';
import Home from './components/home';
import Gallery from './components/gallery';
import AKCStandard from './components/akc-standard';

function App() {
  return (
    <div className="main">
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/damsandsires" element={<DamsAndSires />} />
          <Route path="/breed" element={<Breed />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/akc-standard" element={<AKCStandard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
